import { useEffect, useState, useRef } from "react";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import BackspaceIcon from "../../assets/svgs/BackspaceIcon";
import {
    MainWrapper,
    BackspaceContainer,
    HeaderWrapper,
    HeaderTitleWrapper,
    HeaderTitle,
    EditorWrapper,
    DocWrapper,
    MainPDFWrapper,
    SourceWrapper,
    ThumbnailWrapper,
    TabContainer,
    TabInnerContainer,
    ButtonPanelContainer,
    TabRightIconWrapper
} from "./ReviewGenerationPage.styled";
import speechFormat from "../../helpers/speechFormat";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";
import PdfThumbnailViewer from "../../components/PdfThumbnailViewer/PdfThumbnailViewer";
import PdfViewer from "../../modules/PdfViewer/PdfViewer";
import { generateDocument, generateNonClinicalDocument, getPreviewSharepointPDFFile, getProgress } from "../../apis/ApiServerRequests";
import SuccessICF from "../../modules/SuccessICF/SuccessICF";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";
import DockRightIcon from "../../assets/svgs/DockRightIcon";
import DockBottomIcon from "../../assets/svgs/DockBottomIcon";
import CrossButtonIcon from "../../assets/svgs/CrossButtonIcon";
import useKeyPress from "../../hooks/useKeyPress";


const ReviewGeneratePage = () => {
    const DEFAULT_URL = "https://dms-onlyoffice.agilewriter.smarter.codes";
    const ONLYOFFICE_URL = process.env.REACT_APP_ONLYOFFICE_URL
        ? process.env.REACT_APP_ONLYOFFICE_URL
        : DEFAULT_URL;

    const DEFAULT_PLUGIN = "asc.{EF5735E0-69A7-4D4A-9ABE-F58FD70F479K}"
    const ONLYOFFICE_PLUGIN = process.env.REACT_APP_ONLYOFFICE_PLUGIN
        ? process.env.REACT_APP_ONLYOFFICE_PLUGIN
        : DEFAULT_PLUGIN;

    const navigate = useNavigate();
    const pdfViewerRef = useRef(null);
    const location = useLocation();
    const { requiredData, data } = location.state || {};
    const [fileData, setFileData] = useState(null);

    const [loading, setLoading] = useState(false);
    const [generated, setGenerated] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isEditorFullSize, setIsEditorFullSize] = useState(true);
    const [isUploaded, setIsUploaded] = useState(false);
    const [sourceToggle, setSourceToggle] = useState({'right': 0, "bottom": 0});
    const [successData, setSuccessData] = useState(null);

    const [progress, setProgress] = useState({
        total_mappings: 21,
        mappings_processed: 0,
        estimated_time: 5,
    });

    const [divHeight, setDivHeight] = useState(0);
    const [pdfFile, setPdfFile] = useState(null);

    const [isDisabled, setIsDisabled] = useState(false); // Condition to disable click
    const [companyData, setCompanyData] = useState(null);
    const [selectedFileData, setSelectedFileData] = useState(null);

    useEffect(() => {
        const calculateHeight = () => {
        const availableHeight = window.innerHeight; // Total window height
        const otherElementsHeight = 100; // Example: height of headers or other components
        const calculatedHeight = availableHeight - otherElementsHeight;

        setDivHeight(calculatedHeight);
        };

        // Calculate height on component mount and resize
        calculateHeight();
        window.addEventListener('resize', calculateHeight);

        // Cleanup event listener on component unmount
        return () => {
        window.removeEventListener('resize', calculateHeight);
        };
    }, []);

    var onDocumentReady = function (event) {
        console.log("Document is loaded");
    };

    var onLoadComponentError = function (errorCode, errorDescription) {
        switch (errorCode) {
            case -1: // Unknown error loading component
                console.log(errorCode, errorDescription);
                break;

            case -2: // Error load DocsAPI from http://documentserver/
                console.log(errorCode, errorDescription);
                break;

            case -3: // DocsAPI is not defined
                console.log(errorCode, errorDescription);
                break;
        }
    };


    useEffect(() => {
        if (!companyData) {
            setCompanyData(requiredData)
        }

        if (!selectedFileData) {
            setSelectedFileData(data)
        }

    }, [requiredData, data])


    const handleThumbnailClick = (pageNum) => {
        setPageNumber(pageNum);
    };

    const handleSourceToggle = (mode) => {
        if (isDisabled) {
            return
        }

        if (mode === 'right') {
            if (sourceToggle["right"]) {
                setIsEditorFullSize(true)
                setSourceToggle({'right': 0, "bottom": 0})
            } else {
                setIsEditorFullSize(false)
                setSourceToggle({'right': 1, "bottom": 0})
            }
        } 

        if (mode === 'bottom') {
            if (sourceToggle["bottom"]) {
                setIsEditorFullSize(true)
                setSourceToggle({'right': 0, "bottom": 0})
            } else {
                setIsEditorFullSize(false)
                setSourceToggle({'right': 0, "bottom": 1})
            }
        }
    }

    const [sourceFileName, setSourceFileName] = useState(null)
    const source_file_name = data["source_file_name"]

    const onBack = () => {
        navigate("/");
    };

    useEffect(() => {
        if (!sourceFileName) {
            setSourceFileName(data["source_file_name"][0])
        }
    }, [data])


    useEffect(() => {
        if (companyData && selectedFileData && !loading) {
            setLoading(true);
            const getDocumentfunction = (companyData, selectedFileData) => {
                generateDocument(selectedFileData, companyData)
                    .then(({ data }) => {
                        setSuccessData(data);
                    })
                    .catch(() => {
                        alert("Something went wrong. Try again later.");
                    });
            }

            const getNonClinicalDocument = () => {
                generateNonClinicalDocument(
                    {
                        "output_file_name": companyData["output_file_name"],  
                        "pharma_name": companyData["pharma_name"],  
                        "destination_template_type": selectedFileData["destination_template_type"][0],  
                        "source_file_name": selectedFileData["source_file_name"][0],  
                        "source_template_type": selectedFileData["source_template_type"][0]
                      }
                )
                    .then(({ data }) => {
                        setFileData(data);
                        setGenerated(true)
                        setLoading(false)
                    })
                    .catch(() => {
                        alert("Something went wrong with non-clinical. Try again later.");
                    });
            }
            if (requiredData['selectedDocumentCategory'] === "Non-clinical") {
                getNonClinicalDocument()
            } else {
                getDocumentfunction(companyData, selectedFileData)
            }
        }
    }, [companyData, selectedFileData])



    useEffect(() => {
        if (requiredData['pharma_name'] && sourceFileName && !pdfFile && !isDisabled && requiredData["selectedDocumentCategory"] === "Clinical") {
            setIsDisabled(true)
           const loadPdfFile = async () => {
            try {
                getPreviewSharepointPDFFile(requiredData['pharma_name'], requiredData['selectedDocumentCategory'],sourceFileName, false, "Source Templates").then((response) => {
                    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
                    const url = URL.createObjectURL(pdfBlob);
                    setPdfFile(url);
                    setIsDisabled(false)
                });
            } catch (error) {
              console.error('Failed to load the PDF file', error);
            }
          };
    
          loadPdfFile();
        }
      }, [requiredData, sourceFileName, pdfFile]);

    const saveDocument = () => {
        setLoading(true)
        const event = new KeyboardEvent('keydown', {
            bubbles: true,
            cancelable: true,
            key: 's',
            ctrlKey: true,
        });

        document.dispatchEvent(event);
        setTimeout(() => {
            setLoading(false)
            setIsUploaded(true);
        }, 12000);
    };


    const handleGenerated = (val) => {
        navigate('/generate')
    };

    const getDocProgress = async (process_id) => {
        try {
            getProgress(process_id)
                .then(({ data }) => {
                    setProgress(data);
                    setFileData(data)
                })
                .catch(() => {
                    alert("Something went wrong. Try again later.");
                });
        } catch (error) { }
    };

    useEffect(() => {
        if (successData && requiredData["selectedDocumentCategory"] === "Clinical") {
            let interval;
            if (
                progress.mappings_processed === progress.total_mappings &&
                progress.filename
            ) {
                setLoading(false);
                setGenerated(true);
                setSuccessData(null);
            } else {
                interval = setInterval(() => {
                    getDocProgress(successData.process_id);
                }, 4000);
            }

            return () => {
                clearInterval(interval);
            };
        }
    }, [successData, progress]);


    useKeyPress("G", [], () => saveDocument());

    return (
        <MainWrapper>
            {loading && !generated && (
                <SuccessPopup
                    msg_processing={"Generating Doc, Please wait..."}
                    onClose={() => { }}
                    loading={true}
                    progress={progress}
                    styled={false}
                />
            )
            }

            {loading && generated && (
                <SuccessPopup
                    msg_processing={"Generating Doc, Please wait..."}
                    onClose={() => { }}
                    loading={true}
                />
            )}

            {loading &&  requiredData["selectedDocumentCategory"] === "Non-clinical" && (
                <SuccessPopup
                    msg_processing={"Generating Doc, Please wait..."}
                    onClose={() => { }}
                    loading={true}
                />
            )
            }

            {
                isUploaded && !loading && requiredData["selectedDocumentCategory"] === "Clinical" &&<SuccessICF
                    handleGenerated={handleGenerated}
                    docTitle={fileData?.final_filename}
                    totalMapping={progress?.total_mappings}
                    resolvedMapping={progress?.mappings_processed}
                ></SuccessICF>
            }

            {
                isUploaded && !loading && requiredData["selectedDocumentCategory"] === "Non-clinical" && <SuccessICF
                    handleGenerated={handleGenerated}
                    docTitle={fileData?.filename}
                    totalMapping={progress?.total_mappings}
                    resolvedMapping={progress?.mappings_processed}
                ></SuccessICF>
            }

            {!isUploaded && <HeaderWrapper>
                <BackspaceContainer>
                    <Button
                        icon={<BackspaceIcon />}
                        type={"ghost"}
                        accessKeyText={"[BACKSPACE]"}
                        onClick={onBack}
                        iconStyle={{ marginRight: "4px" }}
                        data-speech={speechFormat({
                            key: "Backspace",
                            action: "go back",
                            item: "to Dashboard",
                        })}
                        buttonStyle={{ paddingTop: "0px" }}
                    />

                </BackspaceContainer>
                <HeaderTitleWrapper>
                    <HeaderTitle>REVIEW GENERATION</HeaderTitle>
                    <div style={{ display: 'flex' }}>
                        <div style={{display: 'flex', alignContent: 'center', alignItems: 'center', gap: '4px'}}>
                            <ButtonPanelContainer
                                onClick={() => handleSourceToggle('right')}
                                active={sourceToggle["right"]}
                            >
                                <DockRightIcon />
                            </ButtonPanelContainer>
                            <ButtonPanelContainer
                                onClick={() => handleSourceToggle('bottom')}
                                active={sourceToggle["bottom"]}
                            >
                                <DockBottomIcon />
                            </ButtonPanelContainer>
                        </div>
                        <Button
                            buttonStyle={{ marginLeft: "6px", marginRight: "10px" }}
                            text={"Create Final Doc"}
                            type={"tertiary"}
                            accessKeyText="[G]"
                            onClick={() => saveDocument()}
                            data-speech={speechFormat({
                                key: "G",
                                action: "",
                                item: "Generate Doc",
                            })}
                        />
                    </div>
                </HeaderTitleWrapper>

            </HeaderWrapper>}
            {generated && !isUploaded &&
                <EditorWrapper sideAlign={sourceToggle["right"]}>
                    <DocWrapper sideAlign={isEditorFullSize || sourceToggle["bottom"]}>
                        {fileData && <DocumentEditor
                            id="docxEditor"
                            documentServerUrl={ONLYOFFICE_URL}
                            config={{
                                "document": {
                                    "fileType": "docx",
                                    "info": {},
                                    "key": "wehfsf345",
                                    "permissions": {},
                                    "title": fileData?.comparison_filename,
                                    "url": fileData?.public_url
                                },
                                "documentType": "word",
                                "editorConfig": {
                                    "callbackUrl": "https://api-dpai.agilewriter.smarter.codes/callback?file=something",
                                    "plugins": {
                                        "autostart": [
                                            ONLYOFFICE_PLUGIN
                                        ]
                                    },
                                    "customization": {
                                        "anonymous": {
                                            "request": false,
                                            "label": "Agilewriter"
                                        },
                                        "autosave": true,
                                        'search': false,
                                        'chat': false,
                                        'headings': false,
                                        'about': false,
                                        'reviewDisplay': false,
                                        "toolbar": true,
                                        "comments": true,
                                        "compactHeader": true,
                                        "compactToolbar": true,
                                        "compatibleFeatures": false,
                                        "forcesave": true,
                                        "help": false,
                                        "hideRightMenu": true,
                                        "hideRulers": true,
                                        "integrationMode": "embed",
                                        "macros": false,
                                        "macrosMode": "Warn",
                                        "mentionShare": false,
                                        "mobileForceView": true,
                                        "plugins": true,
                                        "toolbarHideFileName": false,
                                        "toolbarNoTabs": true,
                                        "uiTheme": "theme-light",
                                        "unit": "cm",
                                        "zoom": 100
                                    }
                                },
                                "height": !isEditorFullSize && sourceToggle["bottom"] ? `${(divHeight/2) - 20}px` : `${divHeight- 40}px`,
                                "token": fileData?.token,
                                "width": "100%",
                                "type": "desktop"
                            }
                            }
                            events_onDocumentReady={onDocumentReady}
                            onLoadComponentError={onLoadComponentError}
                        />}
                    </DocWrapper>
                    {!isEditorFullSize && <DocWrapper sideAlign={isEditorFullSize || sourceToggle["bottom"]}>
                        <TabContainer>
                            <TabInnerContainer>
                                <h4>Source Document</h4>
                                {source_file_name.map((item) => (<Button
                                    type={ source_file_name.length === 1 ? "ghost" : item === sourceFileName ? "brand" : "ghost"}
                                    accessKeyText={item}
                                    onClick={() => setSourceFileName(item)}
                                    extraTextStyle={{ marginLeft: "0px", opacity: "1" }}
                                />))}
                            </TabInnerContainer>
                            <TabRightIconWrapper onClick={() => {
                                setSourceToggle({'right': 0, "bottom": 0})
                                setIsEditorFullSize(true)
                            }}>
                                <CrossButtonIcon />
                            </TabRightIconWrapper>
                        </TabContainer>
                        <SourceWrapper sideAlign={isEditorFullSize || sourceToggle["right"] } divHeight={divHeight}>
                            <ThumbnailWrapper sideAlign={isEditorFullSize || sourceToggle["right"]} divHeight={divHeight}>
                                <PdfThumbnailViewer
                                    file={pdfFile}
                                    numPages={numPages}
                                    onThumbnailClick={handleThumbnailClick}
                                    currentPage={pageNumber}
                                />
                            </ThumbnailWrapper>
                            <MainPDFWrapper ref={pdfViewerRef} sideAlign={isEditorFullSize}>
                                <PdfViewer
                                    file={pdfFile}
                                    isAnnotationOverlay={false}
                                    setNumPages={setNumPages}
                                    pageNumber={pageNumber}
                                    scalorFactor={1.3}
                                />
                            </MainPDFWrapper>
                        </SourceWrapper>

                    </DocWrapper>}
                </EditorWrapper>
            }

        </MainWrapper>
    );
};

export default ReviewGeneratePage;